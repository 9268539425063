import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {CAMPAIGN_DETAIL} from '../../../settings/constants/route-constants'
import {getAsync, patchAsync, putAsync} from '../../../utils/httpClient/HttpClient'
import Loader from '../../../_metronic/partials/content/loader/Loader'
import {campaignBeneficiaryEnum, campaignStatusEnum} from "../../../constants/enums/enums";

function Campaigns() {
    const [campaigns, setCampaigns] = useState([])
    const [isFetchingCampaigns, setIsFetchingCampaigns] = useState(false)
    const [campaignStatuses, setCampaignStatuses] = useState([])
    const [curretnStatus, setCurrentStatus] = useState("");
    const [pageOptions, setPageOptions] = useState({
        page: 1,
        pageSize: 10,
        totalPages: 1,
        order: 'desc',
    });
    const [isForceReload, setIsForceReload] = useState(false);
    const [totalItem, setTotalItem] = useState(0);

    useEffect(() => {
        let statusKeys = Object.keys(campaignStatusEnum)
        let camStatus = []
        statusKeys.forEach(a => {
            let i = campaignStatusEnum[a]
            camStatus.push({'label': a, value: i})
        })
        setCampaignStatuses(camStatus)
        getAllCampaigns()
    }, [])

    const getAllCampaigns = async () => {
        setIsFetchingCampaigns(true)
        const response = await getAsync(`/admin-campaigns/getAllCampaigns?page=${pageOptions?.page}&pageSize=${pageOptions?.pageSize}&order=${pageOptions.order}`)

        if (response.status === 200) {
            const responseData = response.data.body.data;
            setCampaigns(responseData.items);
            setPageOptions(prev => {
                return {
                    ...prev,
                    page: responseData.page,
                    pageSize: responseData.pageSize,
                    totalPages: responseData.pages
                }
            });
            setTotalItem(responseData.total);
            setIsFetchingCampaigns(false);
        } else {
            setIsFetchingCampaigns(false);
        }
    }

    const updateStatus = async (id, status) => {
        debugger
        let res = await patchAsync(`${process.env.REACT_APP_API_URL}admin-campaigns/updateStatus/${id}`, {'status': status})
        if(res.data.statusCode === 200){
            getAllCampaigns()
        }
    }

    const getSelectFieldColor = (value) => {
        switch (value) {
            case 0:
                return 'bg-success text-white';
            case 1:
                return 'bg-info text-white';
            case 2:
                return 'bg-warning text-white';
            case 3:
                return 'bg-danger text-white';
            case 4:
                return 'bg-secondary';
            case 5:
                return 'bg-primary text-white';
            default:
                return 'bg-light';
        }
    }

    function getVisiblePages() {
        let pgs = Array.from(Array(pageOptions.totalPages + 1).keys())
        let result = []
        let start = 0;
        let end = 9;

        if (pageOptions.page === 1) {
            start = 0
        } else {
            start = pageOptions.page - 1
        }

        end = start + 10;
        for (let i = start; i < end; i++) {
            if (pgs[i]) {
                result.push(pgs[i] - 1)
            }
        }

        console.log("result", result, pgs)
        return result;
    }

    return (
        <>
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>Campaigns</span>
                        <span className='text-muted mt-1 fw-bold fs-7'>{totalItem}</span>
                    </h3>
                    {/* <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
            >
              New Member
            </a>
          </div> */}
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {isFetchingCampaigns ? (
                        <Loader/>
                    ) : (
                        <>
                            {/* begin::Table container */}
                            <div className='table-responsive'>
                                {/* begin::Table */}
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    {/* begin::Table head */}
                                    <thead>
                                    <tr className='fw-bolder text-muted'>
                                        <th className='min-w-150px'>Campaign</th>
                                        <th className='min-w-140px'>Target</th>
                                        <th className='min-w-120px'>Raised</th>
                                        <th className='min-w-120px'>Current Balance</th>
                                        <th className='min-w-120px'>Withdrawn</th>
                                        <th className='min-w-130px'>Status</th>
                                        <th className='min-w-100px text-end'>Actions</th>
                                    </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>
                                    {campaigns?.map((campaign) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='symbol symbol-45px me-5'>
                                                            <img
                                                                alt='Profile'
                                                                className='table-data-img'
                                                                src={
                                                                    campaign?.image
                                                                        ? campaign.image
                                                                        : '/media/logos/AbabilFund_Logo.png'
                                                                }
                                                            />
                                                        </div>
                                                        <div className='d-flex justify-content-start flex-column'
                                                             style={{maxWidth: 200}}>
                                                            <Link
                                                                to={`${CAMPAIGN_DETAIL + campaign?._id}`}
                                                                className='text-dark fw-bolder text-hover-primary fs-6'
                                                            >
                                                                {campaign?.title}
                                                            </Link>
                                                            <p className='text-primary'
                                                               style={{fontSize: 12, fontWeight: 'bold'}}>
                                                                {campaign?.campaignCategory &&
                                                                    campaign?.campaignCategory[0].toUpperCase()}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <p className='text-dark fw-bolder text-hover-primary fs-6'>
                                                                {campaign?.desiredAmount ? campaign.desiredAmount : 'N/A'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <p className='text-dark fw-bolder text-hover-primary fs-6'>
                                                                {campaign?.collectedAmount ? campaign.collectedAmount : 0}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <p className='text-dark fw-bolder text-hover-primary fs-6'>
                                                                {campaign?.currentBalance ? campaign.currentBalance : 0}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <p className='text-dark fw-bolder text-hover-primary fs-6'>
                                                                {campaign?.withdrawnAmount ? campaign.withdrawnAmount : 0}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='d-flex justify-content-start flex-column'>
                                                            <select
                                                                value={campaign?.status}
                                                                className={`form-select form-select-solid form-select-lg select2-hidden-accessible ${getSelectFieldColor(campaign?.status)}`}
                                                                onChange={(e) => updateStatus(campaign?._id, parseInt(e.target.value))}
                                                            >
                                                                <option value="">None</option>
                                                                {
                                                                    campaignStatuses?.map((item, index) => <option
                                                                        value={item.value}>{item.label}</option>)
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                                        <Link
                                                            to={`${CAMPAIGN_DETAIL + campaign?._id}`}
                                                            // to={`${CAMPAIGN_DETAIL + '61751ef0994a0cf5a6be8c99'}`}
                                                            //to={`/campaign/detail/overview/${campaign?._id}`}
                                                            className='btn btn-bg-light btn-active-color-primary btn-sm'
                                                        >
                                                            View Details
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                                {/* end::Table */}

                                {/* pagination */}
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        {getVisiblePages().map(a => {
                                            return (<li onClick={() => {
                                                setPageOptions(prev => {
                                                    return {...prev, page: a + 1}
                                                });
                                                setIsForceReload(prev => !prev);
                                            }}
                                                        className={`page-item ${a + 1 === parseInt(pageOptions.page + "") ? 'active' : ''}`}>
                                                <a className="page-link" href="#">{a + 1}</a></li>)
                                        })}
                                    </ul>
                                </nav>
                                {/* pagination */}
                            </div>
                            {/* end::Table container */}
                        </>
                    )}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default Campaigns
