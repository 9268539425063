export const campaignEditTabEnum = Object.freeze({
    "IDENTITY": 0,
    "DETAIL": 1,
    "STORY": 2,
    "ORG_DETAIL":3,
    "BENIFICIARY_DETAIL": 4,
    "WITHDRAWLS": 5
})

export const campaignStatusEnum = Object.freeze({
    "draft": 0,
    "approved": 1,
    "disabled": 2,
    "completed": 3,
    "rejected": 4,
    "underReview": 5,
    "deleted": 6
})

export const campaignBeneficiaryEnum = Object.freeze({
    "mysefl": "myself",
    "friend": "friend",
    "organization": "organization"
})

export const paymentMethodEnum = Object.freeze({
    "aamarpay": "aamarpay",
    "bkash": "bkash",
    "rocket": "rocket",
    "paypal": "Paypal"
})

export const CurrencyEnum = Object.freeze({
    "BDT": "BDT",
    "USD": "USD"
})