/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react'
import {
    Redirect,
    Switch,
    Route,
    BrowserRouter as Router
} from 'react-router-dom'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {AuthPage, Logout} from '../ababilmodules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {getAuthData} from '../../utils/auth/auth'
import {SUCCESS_LOGIN} from '../../settings/constants/constants'
import {DashboardWrapper} from "../pages/dashboard/DashboardWrapper";


const Routes = () => {
    const dispatch = useDispatch()
    //const isAuthorized = useSelector(({auth}) => auth.user, shallowEqual)
    let tokenn = useSelector(({auth}) => auth)

    let {token} = getAuthData();
    // if (!tokenn.token) {
    //     if (token) {
    //         let res = {
    //             token,
    //             userInfo: null
    //         }
    //         dispatch({type: SUCCESS_LOGIN, payload: res})
    //     }
    // }
    //const isAuthorized = tokenn.token ? true : false
    const isAuthorized = token ? true : false


    return (
        <Switch>
            {!token ? (
                /*Redirect to `/auth` when user is not authorized*/
                // <Redirect to='/auth/login'/>
                <Route>
                    <AuthPage/>
                </Route>
            ) : (
                <MasterLayout>
                    <PrivateRoutes/>
                </MasterLayout>
            )}
        </Switch>
    )
}

export {Routes}
