import React from 'react';

const Loader = () => {
    return (
        <div className='loader-container'>
            <img src={'/media/logos/AbabilFund_Logo.png'} alt='Start logo' />
            <span span>Loading ...</span>
        </div>
    );
};

export default Loader;