export const setAuthData = (data) => {
    //set token for the old system that system not crash.remove this when all set to userToken
    const { token } = data
    clearLocalStorage("user_token")
    localStorage.setItem("user_token", token)
    console.log(token)
    //let userInf = JSON.parse(atob(token.split(".")[1]))
    // console.log("Exp time:")
    // console.log(new Date(userInf.token_valid_time * 1000))
    // console.log(userInf.token_valid_time * 1000)
    // console.log("Now:")
    // console.log(new Date())
}

export const getAuthData = () => {
    let userToken = null
    let token = localStorage.getItem("user_token")
    let user = null
    // let authInfo = JSON.parse(atob(token.split(".")[1]));

    // if(!token){
    //     user = JSON.parse(atob(token.split(".")[1]));

    //     let expDate = new Date(user.token_valid_time * 1000)
    //     let dateNow = new Date().getTime()


    //     if(expDate < dateNow){
    //         //localStorage.clear()
    //         clearLocalStorage("user_token")
    //         clearLocalStorage("beeUid")
    //         clearLocalStorage("localData")
    //         console.log("Token Expired !!!")
    //         token = null
    //         user = null
    //     }
    // }

    if(token === "undefined"){
        token = null
    }
    return {
        "token": token,
        "user": user
    }
}

export const isAuthenticated = () => {

}

export const clearLocalStorage = (items) =>{
    let keys = []
    if(items === undefined){
        keys = Object.keys(localStorage)
    }else {
        keys = items.split(",")
    }


    for(let i = 0;i < keys.length;i++){
        localStorage.removeItem(keys[i])
    }

}