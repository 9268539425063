import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {Routes} from './routing/Routes';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';


const App = () => {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <BrowserRouter>
                <I18nProvider>
                    <LayoutProvider>
                        <Routes/>
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="colored"
                        />
                    </LayoutProvider>
                </I18nProvider>
            </BrowserRouter>
        </Suspense>
    )
}

export {App}
