import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

//constants
import * as RouteConst from "../../settings/constants/route-constants"
import Campaigns from '../pages/campaigns/Campaigns'
import {ErrorsPage} from "../modules/errors/ErrorsPage";
import {Logout} from "../ababilmodules/auth";

export function PrivateRoutes() {
    debugger
    const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const UserPage = lazy(() => import('../modules/user/UserPage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    const Users = lazy(() => import('../pages/users/Users'))
    const UserDetail = lazy(() => import('../pages/users/UserDetailPage'))
    const Campaigns = lazy(() => import('../pages/campaigns/Campaigns'))
    const CampaignDetails = lazy(() => import('../containers/campaign/CampaignDetailsPage'))

    return (
        <Suspense fallback={<FallbackView/>}>
            {/*<Switch>*/}
                <Route path='/dashboard' component={DashboardWrapper}/>
                <Route path={RouteConst.USERS_ROUTE} component={Users}/>
                <Route path={`${RouteConst.USER_DETAIL}:id`} component={UserDetail}/>
                <Route path={`${RouteConst.CAMPAIGN_DETAIL}:id`} component={CampaignDetails}/>


                <Route path='/builder' component={BuilderPageWrapper}/>
                <Route path='/crafted/pages/profile' component={ProfilePage}/>
                <Route path='/users' component={Users}/>
                <Route path='/campaigns' component={Campaigns}/>

                {/*<Route path='/crafted/pages/user/settings' component={SettingsPage}/>*/}
                <Route path='/crafted/pages/user/organizations' component={UserPage}/>
                <Route path='/crafted/pages/wizards' component={WizardsPage}/>
                <Route path='/crafted/widgets' component={WidgetsPage}/>
                {/*<Route path='/crafted/account' component={AccountPage} />*/}
                <Route path='/apps/chat' component={ChatPage}/>

                <Route path='/error' component={ErrorsPage}/>
                <Route path='/logout' component={Logout}/>

                {/*<Redirect from='/' to='/dashboard'/>*/}
                <Redirect exact from='/' to='/dashboard'/>
                {/*<Redirect to='error/404'/>*/}
            {/*</Switch>*/}
        </Suspense>
    )
}
