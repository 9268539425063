/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

//redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../../../setup/redux/auth/authActions";
import {getAuthData, setAuthData} from "../../../../utils/auth/auth";
import {postAsync} from "../../../../utils/httpClient/HttpClient";
import {FAILED_LOGIN, SUCCESS_LOGIN} from "../../../../settings/constants/constants";


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

function Login(props) {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [isAdmin, setIsAdmin] = useState(true)
    const [isDisableLogin, setIsDisableLogin] = useState(false)

    const {auth} = props

    function handleRequestLogin(event) {
        event.preventDefault()
        props.requestLogin(userName, password, isAdmin)
    }

    const handleLogin = async () => {
        setLoading(true)
        setIsDisableLogin(true)
        let url = `${process.env.REACT_APP_API_URL}users/login`

        const data = {
            userName,
            password,
            isAdmin
        }
        try {
            let response = await postAsync(url, data)
            if (response) {
                let res = response.data.body.data
                setAuthData(res)
                //history.push("/auth/login")
                window.location.reload()
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
            setIsDisableLogin(false)
            //dispatch({type: FAILED_LOGIN})
            setAuthData({})
        }
    }


    return (
        <form
            className='form w-100'
            onSubmit={handleRequestLogin}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Sign In to Ababil</h1>
                <div className='text-gray-400 fw-bold fs-4'>
                    New Here?{' '}
                    <Link to='/auth/registration' className='link-primary fw-bolder'>
                        Create an Account
                    </Link>
                </div>
                {auth.loginStatus === "failed" &&
                    <div className='text-gray-400 fw-bold fs-4'>
                        <h3 style={{color: "red"}}>Failed to login</h3>
                    </div>
                }

            </div>

            {/* begin::Heading */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    placeholder='Email'
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                    )}
                    type='email'
                    name='email'
                    onChange={(e) => setUserName(e.target.value)}
                    autoComplete='off'
                />
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                        {/* begin::Label */}
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                        {/* end::Label */}
                        {/* begin::Link */}
                        <Link
                            to='/auth/forgot-password'
                            className='link-primary fs-6 fw-bolder'
                            style={{marginLeft: '5px'}}
                        >
                            Forgot Password ?
                        </Link>
                        {/* end::Link */}
                    </div>
                </div>
                <input
                    type='password'
                    autoComplete='off'
                    onChange={(e) => setPassword(e.target.value)}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                    )}
                />
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    // onClick={handleRequestLogin}
                    disabled={isDisableLogin}
                    onClick={(e) => {
                        e.preventDefault()
                        handleLogin()
                    }}
                >
                    {!auth.isRequested && <span className='indicator-label'>Continue</span>}
                    {auth.isRequested && (
                        <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                    )}
                </button>


            </div>
            {/* end::Action */}

        </form>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({...actionCreators}, dispatch)
)(Login);